<template>
  <div class="flex flex-col h-full">
    <h6 class="mb-10">Advanced Account Verification</h6>

    <div class="mb-10">
      <template v-for="(option, optionIndex) in verificationOptions">
        <selection-option
          :key="optionIndex"
          :option="option"
          :is-selected="option === selectedOption"
          @click.prevent="selectOption(option)"
        />
      </template>
    </div>

    <button
      type="button"
      class="btn btn-blue btn-md mt-auto relative w-full"
      :disabled="!selectedOption"
      @click.prevent="submit"
    >
      <span>Proceed</span>
      <i class="absolute inline-flex right-20px top-1/2" style="transform: translateY(-50%);">
        <ion-icon name="arrow-forward-outline" />
      </i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'AccountVerificationSelection',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedOption: null
    };
  },
  components: {
    selectionOption: () => import('./SelectionOption'),
  },
  computed: {
    verificationOptions() {
      return this.options.map(option => {
        switch (option) {
          case 'work-email':
            return {
              name: 'work-email',
              label: 'Verify with workplace email',
              description: [
                'We will send an OTP to your workplace email.',
                'Approval takes few minutes.',
              ],
            };
          case 'bank-account':
            return {
              name: 'bank-account',
              label: 'Verify with bank account',
              description: [
                'Recommended for users with no workplace email.',
                'Approval takes few hours.',
              ],
            };
        }
      });
    },
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
    },
    submit() {
      if (this.selectedOption) {
        this.$emit('select', this.selectedOption.name);
      }
    },
  }
};
</script>
